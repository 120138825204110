import React, {useContext, useState} from 'react';

const ThemeContext = React.createContext(null)

const useThemeContext = () => useContext(ThemeContext);

function ThemeProvider({children}) {
  let themeToUse;
  const prefersDark = typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)').matches;
  let themeInWindow;
  
  try {
    themeInWindow = typeof window !== 'undefined' && localStorage.getItem('theme');
    if (themeInWindow) {
      themeToUse = localStorage.getItem('theme');
    } else {
      themeToUse = prefersDark ? 'dark' : 'light'
    }
  } catch (e) {
    themeToUse = prefersDark ? 'dark' : 'light'
  }
  
  const [theme , setTheme] = useState(themeToUse);
  
  return (
      <ThemeContext.Provider value={{theme, setTheme}}>
        {children}
      </ThemeContext.Provider>
  )
}


export  {ThemeProvider, useThemeContext};
