const siteConfig = {
  siteTitle: "Abdullah (abba) - Software Developer",
  siteUrl: "https://abba.dev",
  description: "Personal website and blog for Abdullah (abba), a Full-Stack Web Developer. I like to talk about web development and how apps gets deployed to the cloud.",
  siteLogo: "/logos/logoImg.png",
  twitterUser: "abbadev",
  commentsRepo: 'abbathaw/blog-comments',
  navLinks: [
    {
      name: 'Home',
      link: '/',
    },
    {
      name: 'About',
      link: '/about',
    },
    {
      name: 'Blog',
      link: '/blog',
    },
    // {
    //   name: 'Projects',
    //   link: '/projects',
    // },
    {
      name: 'Contact Me',
      link: '/contact',
    },
  ],
  categories: [
    {
      name: `All`,
      link: '/blog',
      slug: '',
      visible: true
    },
    {
      name: `AWS`,
      link:  '/categories/aws',
      slug: 'aws',
      visible: true
    },
    {
      name: `Books`,
      link:  '/categories/books',
      slug: 'books',
      visible: true
    },
    {
      name: `Cloud`,
      link:  '/categories/cloud',
      slug: 'cloud',
      visible: false
    },
    {
      name: `Code`,
      link: '/categories/code',
      slug: 'code',
      visible: true
    },
    {
      name: `JavaScript`,
      link: '/categories/javascript',
      slug: 'javascript',
      visible: true
    },
    {
      name: `Personal`,
      link:  '/categories/personal',
      slug: 'personal',
      visible: true
    },
    {
      name: `Software Design`,
      link:  '/categories/software-design',
      slug: 'software-design',
      visible: true
    },
    {
      name: `Tutorial Series`,
      link:  '/categories/tutorials',
      slug: 'tutorials',
      visible: true
    },
  ]
}

module.exports = {siteConfig}
