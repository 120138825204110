import React from 'react';
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import LightOn from '../../assets/lightOn.svg';
import LightOff from '../../assets/lightOff.svg';
import styled from 'styled-components';
import * as variables from '../styles/style-config';
import {useThemeContext} from '../context/theme-context';


const ThemeTogglerButton = () => {
  
  const { setTheme } = useThemeContext();
  
  const handleChange = (isChecked) => {
    const selectedTheme  = isChecked ? 'dark' : 'light'
    setTheme(selectedTheme)
  }
  
  
  return (
      <>
        <ThemeToggler>
          {({ theme, toggleTheme }) => {
            if (theme == null) {
              return null
            }
            return (
            <label>
              <input
                  type="checkbox"
                  className="theme-changer"
                  onChange={async e => {
                    const isChecked = e.target.checked;
                    await handleChange(isChecked)
                    toggleTheme(isChecked ? 'dark' : 'light')
                  }}
                  checked={theme === 'dark' }
              />{' '}
              <ImageContainer>
                <LightOff className="gg-sun"/>
                <LightOn className="gg-moon"/>
              </ImageContainer>
            </label>
            )}}
        </ThemeToggler>
      </>
  );
};

const ImageContainer = styled.div`
  cursor: pointer;
	margin-left: 0.2rem;
	@media (min-width: ${variables.breakpoints.small}) {
    margin-left: 1rem;
  }
	svg {
	  width: 1.2rem;
	  height: 100%;
	}
`;

export default ThemeTogglerButton;
