import React from 'react';
import styled from 'styled-components';
import * as variables from '../styles/style-config';

const ContactForm = () => {
  return (
      <FormContainer>
        <form name="contact" method="POST"  data-netlify="true" netlify-honeypot="bot-field" action="/thank-you">
          <input type="hidden" name="form-name" value="contact" />
          <div>
            <Label>
              Name
              <Input type="text" name="name" id="name"/>
            </Label>
          </div>
          <div>
            <Label>
              Email *
              <Input type="email" name="email" id="email" required/>
            </Label>
          </div>
          <div>
            <Label>
              Subject
              <Input type="text" name="subject" id="subject"/>
            </Label>
          </div>
          <div>
            <Label>
              Message *
              <InputText name="message" id="message" rows="5" required/>
            </Label>
          </div>
          <Button type="submit"><h4>Send</h4></Button>
          <BotField>
            <label>Don’t fill this out if you're human: <input
                name="bot-field"/></label>
          </BotField>
        </form>
      </FormContainer>
  );
};

 const FormContainer =styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: minmax(30%, 450px);
 @media (min-width: ${variables.breakpoints.small}) {
   justify-content: start;
 }
`
const Label = styled.label`
  color: var(--bodyText);
  margin: 1.8rem 0;
  font-size: 0.8rem;
`

 const Input = styled.input`
  width: 100%;
  border: 1px solid var(--light-grey);
  background-color: var(--bg2);
  padding: 0.5rem;
  color: var(--bodyText);
  border-radius: 4px;
`;

 const InputText = styled.textarea`
  width: 100%;
  border: 1px solid var(--light-grey);
  background-color: var(--bg2);
  padding: 0.5rem;
  color: var(--bodyText);
  border-radius: 4px;
`;

 const Button = styled.button`
  margin-top: 0.5rem ;
  height: 35px;
  width: 100%;
  font-weight: 600;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--menu-active-color) ;
  cursor: pointer;
  h4 {
    //color: #fff;
    text-transform: uppercase;
  }
  &:hover {
  border: none;
  background-color: var(--textLinkHover);
  }
`;
 
 const BotField = styled.div`
    visibility: hidden;
`

export default ContactForm;
