import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import * as variables from '../styles/style-config'
import NewsletterForm from './NewsletterForm';
import Comment from './Comment'
import {useThemeContext} from '../context/theme-context';
import {siteConfig} from '../utils/site-config';
import * as Sentry from "@sentry/gatsby"

const PostFooter = ({tags, next, previous}) => {
  
  const prevPost = previous ? previous : '';
  const nextPost = next ? next : '';
  
  const showReadMore = prevPost || nextPost;
  const bothPrevAndNext = prevPost && nextPost;
  
  const commentBox = React.createRef();
  const { theme } = useThemeContext();
  
  useEffect(() => {
    const commentScript = document.createElement('script')
    const commentsTheme = theme && theme === 'dark'
            ? 'github-dark'
            : 'github-light'
    commentScript.async = true
    commentScript.src = 'https://utteranc.es/client.js'
    commentScript.setAttribute('repo', siteConfig.commentsRepo)
    commentScript.setAttribute('issue-term', 'pathname')
    commentScript.setAttribute('id', 'utterances')
    commentScript.setAttribute('label', 'blog-comment')
    commentScript.setAttribute('theme', commentsTheme)
    commentScript.setAttribute('crossorigin', 'anonymous')
    if (commentBox && commentBox.current) {
      commentBox.current.appendChild(commentScript)
    } else {
      console.log(`Error adding utterances comments on: ${commentBox}`)
      Sentry.captureMessage("Error adding utterances comments");
    }
    const removeScript = () => {
      commentScript.remove();
      document.querySelectorAll(".utterances").forEach(el => el.remove());
    };
  
    return () => {
      removeScript();
    };
  
  }, [theme]) // eslint-disable-line

  
  return (
      <Container>
        
        <Section>
          <Title>
            Tags
          </Title>
          <TagDiv>
            {tags.map((tag, index)=> {
              return (<div key={index}><Tag>{tag}</Tag></div>)
            })}
          </TagDiv>
        </Section>
  
        <Section>
          <Title>
            Comments
          </Title>
           <Comment ref={commentBox} />
        </Section>
        
        {
          showReadMore && (
              <ReadMoreSection showBoth={bothPrevAndNext}>
                
                {prevPost && (
                    <div>
                    <Title>
                      Read next
                    </Title>
                    <Link to={`/blog/${prevPost.frontmatter.slug}`}>
                      <ArticleLink>
                        <h4>{prevPost.frontmatter.title}</h4>
                        <Subtitle post={prevPost} />
                      </ArticleLink>
                    </Link>
                    </div>
                )}
                
                {nextPost && (
                    <div>
                    <Title>
                      Read Previous
                    </Title>
                    <Link to={`/blog/${nextPost.frontmatter.slug}`}>
                      <ArticleLink>
                        <h4>{nextPost.frontmatter.title}</h4>
                        <Subtitle post={nextPost} />
                      </ArticleLink>
                    </Link>
                    </div>
                )}
              </ReadMoreSection>
          )
        }
        <Section>
          <Title>
            Subscribe to the newsletter
          </Title>
          <NewsletterForm/>
        </Section>
      </Container>
  );
};

const Subtitle = ({post}) => {
  return(
    <MetadataContainer>
      <div>{post.frontmatter.date}</div>
      <BullDot/>
      <div> {post.timeToRead} min read</div>
    </MetadataContainer>
  )
}

const MetadataContainer =styled.div`
	grid-row: 1/ span 1 ;
	display: flex;
	flex-wrap: wrap;
	color: var(--light-grey);
	font-size: 0.7rem;
	font-weight: 300;
	align-items: center;
	margin-top: 0.5rem;
`;

const ReadMoreSection = styled.div`
  display: grid;
  grid-column-gap: 0.3rem;
 @media (min-width: ${variables.breakpoints.small}) {
    grid-template-columns: ${props => props.showBoth ? `1fr 1fr` :`1fr`};
 }
`

const BullDot = styled.span`
	width: 0.25rem;
	height:  0.25rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	background: var(--light-grey);
	border-radius: 9999px;
`;

const Section = styled.div`
  margin: 1.2rem 0;
`;

const Title = styled.h5`
    margin-top: .5rem;
    margin-bottom: 1rem;
    padding-bottom: .25rem;
    border-bottom: 2px solid var(--very-light-grey);
`;

const ArticleLink = styled.div`
    display: block;
    font-weight: 400;
    border-radius: 0.2rem;
    box-shadow: 0 3px 13px rgba(100, 110, 140, 0.1),
    0 2px 4px rgba(100, 110, 140, 0.15);
    font-size: 0.9rem;
    background: var(--bg2);
    padding: 1rem;
    margin-top: 1rem;
    :hover {
      transform: translate3D(0, -1px, 0);
      box-shadow: var(--cardShadowHover);
    }
`

const Container = styled.div`
  display: grid;
  width: 100%;
  justify-self: center;
  padding: 1rem;
  border-radius: 0.6rem;
     @media (min-width: ${variables.breakpoints.small}) {
       //max-width: 80%;
     }
`;

const TagDiv = styled.div`
	display: flex;
	flex-wrap: wrap;
`

const Tag = styled.span`
  background: #f1f3f5;
  color: #3d78b3;
  display: block;
  font-weight: 500;
  padding: .4rem .6rem;
  font-size: 0.75rem;
  border-radius: 3rem;
  line-height: 1;
  margin: .2rem .2rem .2rem 0.2rem;
`;

export default PostFooter;
