import styled from "styled-components";
import React from 'react';
import * as variables from '../../styles/style-config';
const Filename = ({filename}) => {
  
  return (<FilenameStyle>{filename}</FilenameStyle>)
}

const FilenameStyle = styled.div`
  background: ${variables.components.fileName.background};
  padding: 0.5rem 1rem 0.75rem;
  font-size: 0.8rem;
  color:  ${variables.components.fileName.textColor};
  font-weight: bold;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-bottom: -0.3rem;
`;

export default Filename;
