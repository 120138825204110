import React from 'react';
import styled from 'styled-components';
import * as variables from '../styles/style-config'

const PageBodyLayout = ({ title, subtitle, image, children, hasSidebar=false, sidebar}) => {
	return (
			<Wrapper>
				<HeroImg>{image}</HeroImg>
				<TopContainer>
					<Title>{title}</Title>
					<SubTitle>{subtitle}</SubTitle>
				</TopContainer>
				{
					hasSidebar && <Sidebar>{sidebar}</Sidebar>
				}
				<ChildrenContainer>
					{children}
				</ChildrenContainer>
			</Wrapper>
	);
};

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1.2rem;
  grid-template-columns: 1fr;
  grid-template-rows: [ title-start] max-content [title-end hero-image] auto [hero-image-end sidebar-row-start] max-content [sidebar-row-end children-start] auto [children-end];
   @media (min-width: ${variables.breakpoints.small}) {
     grid-template-rows: [ title-start] max-content [title-end hero-image] minmax(12.5rem, max-content)  [hero-image-end sidebar-row-start] max-content [sidebar-row-end children-start] auto [children-end];
      grid-template-columns: [sidebar-start] 3fr [sidebar-end] 5fr [center-end] ;
  }
   @media (min-width: ${variables.breakpoints.big}) {
      grid-template-columns: [sidebar-start] 3fr [sidebar-end] 5fr [center-end] 2fr [margin-end];
  }
`;

const TopContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-row: title-start/ title-end;
  grid-column: 1 / 1;
   @media (min-width: ${variables.breakpoints.small}) {
       grid-column: 2/ span 1;
  }
  max-width: var(--children-max-width);
`

const Title = styled.div`
  grid-row: 1/ span 1;
  grid-column: 1 / span 1;
`

const SubTitle = styled.div`
  grid-row: span 1/ -1;
  grid-column: 1 / span 1;
	max-width: 50rem;
`

const HeroImg = styled.div`
    grid-row: hero-image/ hero-image-end;
    grid-column: span 1 / -1;
    align-self: start;
    justify-self: center;
   @media (min-width: ${variables.breakpoints.small}) {
   		grid-row: title-start / hero-img-end ;
      grid-column: sidebar-start/ sidebar-end;
  	}
  svg {
     height: 14rem;
     width: 100%;
     @media (min-width: ${variables.breakpoints.small}) {
        height: 14rem;
     }
     @media (min-width: ${variables.breakpoints.big}) {
        height: 15rem;
      }
  }
`

const Sidebar = styled.div`
	grid-row: sidebar-row-start /sidebar-row-end ;
	grid-column: span 1 / -1;
   @media (min-width: ${variables.breakpoints.small}) {
   	justify-self: center;
   	grid-row: sidebar-row-start / sidebar-row-end ;
   	grid-column: sidebar-start/ sidebar-end;
   }
	
`;

const ChildrenContainer = styled.div`
  max-width: var(--children-max-width);
	grid-row: children-start/ children-end;
  grid-column: 1 / -1;
   @media (min-width: ${variables.breakpoints.small}) {
       grid-column: 2/ span 1;
       grid-row: title-end/ children-end;
  }
`

export  {PageBodyLayout};
