import React from 'react';
import {GlobalLayout, PageBodyLayout} from '../layouts';
import {Helmet} from 'react-helmet';
import IndexImage from '../../assets/indexImage.svg';
import {graphql, Link} from 'gatsby';
import BlogListMinimal from '../components/BlogListMinimal';
import styled, {ThemeProvider} from 'styled-components';
import SEO from '../components/SEO';
import RoundedButton from '../components/RoundedButton';

const Title = () => (
		<>
			<h1>
				Hey, I'm Abdullah (abba)
			</h1>
		</>
);

const Subheader = () => (
		<>
			<p>I am an <strong>AWS Community Builder</strong> and a <strong>full-stack web developer </strong>.</p>
			<p>
				I like to talk about web development, architecture and how
				apps gets deployed to the cloud.
			</p>
			<p>
				You can read my <ActionLink to={"/blog"}>blog</ActionLink>, learn more <ActionLink to={"/about"}>about</ActionLink> me, or subscribe to my <ActionLink to={"/newsletter"}>newsletter</ActionLink>.
			</p>
		</>
);

const Index = ({data}) => {
	const {edges} = data.allMdx;
	return (
			<>
				<GlobalLayout>
					<Helmet title='Hello'/>
					<SEO />
					<PageBodyLayout title={<Title/>}
													subtitle={<Subheader/>} image={<IndexImage />}>
						<div>
							<HeaderContainer>
								<Header>Latest articles</Header>
								<ThemeProvider theme={ButtonTheme}>
									<RoundedButton name={"View all"} link={'/blog'}/>
								</ThemeProvider>
							</HeaderContainer>
							<BlogListMinimal edges={edges} />
						</div>
					</PageBodyLayout>
				</GlobalLayout>
			</>
	);
}

const ActionLink = styled(Link)`
	color: var(--body-text);
	font-size: 1.1em;
	font-weight: 800;
	text-decoration: underline;
	text-decoration-thickness: 3px;
	text-decoration-color: var(--primary-theme-color);
	&:hover {
		color: var(--secondary-theme-color);
	}
`

const ButtonTheme = {
	background: "#fff4e6",
	color: "#e8590c",
}

const HeaderContainer = styled.div`
	display: grid;
	grid-template-columns: max-content max-content;
	align-items: start;
`

const Header = styled.h2`
 display: table;
 line-height: 1;
	&::after {
	content:'';
	display:block;
	width:100%;
	height:0.15rem;
	background: var(--primary-theme-color);
	margin: 0.3rem auto 0.3rem;
	border-radius:23px;
	}
`

export const pageQuery = graphql`
  query IndexBlogListQuery {
     allMdx(
     sort: { fields: [frontmatter___date], order: DESC }
     limit: 5
     filter: {
       frontmatter: {
         template: {eq: "post"},
         isPublished: {eq: true}
        }
     }
     ) {
        edges {
          node {
            frontmatter {
              slug
              title
              description
              category
              date(formatString: "MMMM Do, YYYY")
               image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
            timeToRead
          }
      }
   }
  }
`;

export default Index;

