import styled from "styled-components";

const BlockQuote = styled.blockquote`
  margin: 1rem 0;
  padding: 1rem;
  background: var(--blockquote-bg);
  border-radius: 0.35rem;
`;

export default BlockQuote;
