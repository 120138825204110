import React from 'react';
import {FooterStyled} from '../layouts/GridLayouts';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {faHandHoldingHeart} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  // faDev,
  faGithub,
  // faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
      <FooterStyled>
      <Wrapper>
        <MenuContainer>
          <MenuList>
            <MenuItem>
              <Link to="/" activeClassName="active">
                Home
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/blog" activeClassName="active">
                Articles
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/newsletter" activeClassName="active">
                Newsletter
              </Link>
            </MenuItem>
          </MenuList>
        </MenuContainer>
        <Socials>
          <a href="https://www.twitter.com/abbadev">
            <FontAwesomeIcon title="twitter" icon={faTwitter} />
          </a>
          <a href="https://github.com/abbathaw">
            <FontAwesomeIcon title="github" icon={faGithub} />
          </a>
          {/*<a href="https://dev.to/abbadev">*/}
          {/*  <FontAwesomeIcon title="devto" icon={faDev} />*/}
          {/*</a>*/}

          {/*<a href="https://www.linkedin.com/in/aalthawr">*/}
          {/*  <FontAwesomeIcon title="linkedin" icon={faLinkedin} />*/}
          {/*</a>*/}
          {/*<a href="https://ko-fi.com/abbadev">*/}
          {/*  <FontAwesomeIcon title="kofi" icon={faHandHoldingHeart} />*/}
          {/*</a>*/}
        </Socials>
      </Wrapper>
      </FooterStyled>
  );
};

const Socials = styled.div`
  align-self: center;
  margin-bottom: 2rem;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
  a {
    color: var(--icons);
    margin: 0 1rem;
    
  }
  a:hover {
    color: var(--textLinkHover);
  }
`


const MenuContainer = styled.div`
  justify-self: center;
`

const MenuList = styled.ul`
  padding: unset;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: space-between;
`
const MenuItem = styled.li`
  margin: 0 10px;
  a {
    color: var(--bodyText);
    font-size: 0.9rem;
    font-weight: 400;
    text-decoration: none;
    transition: color 0.3s ease-out;
    white-space: nowrap;
  }
  
  a:hover {
    color: var(--textLinkHover);
  }
`;

const Wrapper = styled.div`
  display: grid;
  justify-items: center;
`;

export default Footer;
