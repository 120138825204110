import React from 'react';
import {GlobalLayout, PageBodyLayout} from '../layouts';
import {Helmet} from 'react-helmet';
import ContactImg from '../../assets/contactImg.svg';

const Title = () => (
    <>
      <h2>Say Hello</h2>
    </>
);

const ThankYou = () => {
  return (
      <>
        <GlobalLayout>
          <Helmet title='Thank you'/>
          <PageBodyLayout title={<Title/>}
                          subtitle={<p> Thank you for your message!</p>} image={<ContactImg/>}>
          </PageBodyLayout>
        </GlobalLayout>
      </>
  );
};

export default ThankYou;
