import React from 'react';
import {graphql} from 'gatsby';
import BlogList from '../components/BlogList';
import {siteConfig} from '../utils/site-config';
import {Helmet} from 'react-helmet';
import SEO from '../components/SEO';

const CategoryTemplate = ({data, pageContext}) => {
  const {edges} = data.allMdx;
  
  const categoryData = siteConfig.categories.filter(c=> c.slug===pageContext.category)[0]
  
  const Title = () => (
      <>
        <h2>{categoryData.name}</h2>
      </>
  );
  
  return (
        <>
          <SEO customTitle={categoryData.name}/>
          <BlogList title = {<Title/>} edges={edges} />
          <Helmet title={categoryData.name}/>
      </>
  )
  
};



export const pageQuery = graphql`
  query getCategoryList($category: String!) {
     allMdx(
     sort: { fields: [frontmatter___date], order: DESC }
     filter: {
       frontmatter: {
         template: {eq: "post"},
         isPublished: {eq: true},
         category: {eq: $category}
        }
     }
     ) {
        edges {
          node {
            frontmatter {
              slug
              title
              description
              category
              date(formatString: "MMMM Do, YYYY")
               image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
            timeToRead
          }
      }
   }
  }
`;

export default CategoryTemplate;
