import React from 'react';
import {graphql} from 'gatsby';
import BlogList from '../components/BlogList';

const Title = () => (
    <>
      <h2>Blog</h2>
    </>
);

const Blog = ({data}) => {
  const {edges} = data.allMdx;
  
  return <BlogList title = {<Title/>} edges={edges} />
};

export const pageQuery = graphql`
  query BlogQuery {
     allMdx(
     sort: { fields: [frontmatter___date], order: DESC }
     filter: {
       frontmatter: {
         template: {eq: "post"},
         isPublished: {eq: true}
        }
     }
     ) {
        edges {
          node {
            frontmatter {
              slug
              title
              description
              category
              date(formatString: "MMMM Do, YYYY")
               image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
            timeToRead
          }
      }
   }
  }
`;

export default Blog;
