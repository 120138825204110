import React from 'react';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import { graphql} from 'gatsby';
import {GlobalLayout, PageBodyLayout} from '../layouts';
import {Helmet} from 'react-helmet';
import ContactImg from '../../assets/contactImg.svg'
import AboutImg from '../../assets/aboutImg.svg'
import Newsletter from '../../assets/newsletter.svg';
import SEO from '../components/SEO';

const PageTemplate = ({data}) => {
	const {title, heroImage, subtitle} = data.mdx.frontmatter;
	const {body} = data.mdx;
	
	
	const Title = () => (
			<>
				<h1>{title}</h1>
			</>
	);
	
	const Subtitle = () => (
			<> {subtitle} </>
	)
	
	const getSvgAsset = (heroImg) => {
		const imageAssets = {
			ContactImg: <ContactImg/>,
			AboutImg: <AboutImg/>,
			Newsletter: <Newsletter/>
		}
		return imageAssets[heroImg];
	}
	
	
	return (
			<>
				<GlobalLayout>
					<Helmet title={title} />
					<SEO customTitle={title}/>
					<PageBodyLayout title={<Title/>}
					                subtitle={<Subtitle/>} image={getSvgAsset(heroImage)}>
						<MDXRenderer>
							{body}
						</MDXRenderer>
					</PageBodyLayout>
				</GlobalLayout>
			</>	);
};


export const query = graphql`
  query getPage($slug: String!) {
      mdx(frontmatter: { slug: { eq: $slug } }) {
        frontmatter {
          title
          subtitle
          heroImage
        }
        body
      }
    }`;

export default PageTemplate;
