export const breakpoints = {
  verySmall: "20rem",         //320px,
  small: "48rem",     //768px
  big: "62rem",            //992px
  large: "75rem"          //1200px
}

export const colors = {
  mainWhite: `#fff`,
  darkBlack: '#09090a',
  mainBlack: `#282c35`,
  mainBlack2: '#364050',
  mainBlack3: 'rgb(21, 32, 43)',
  lightGrey: `rgba(36,39,49,.4)`,
  veryLightGrey: `#e3e9ed`,
}

export const themeColors = {
  primary: `#f8821e`,// try later 00bcd4
  secondary: `#f720a1`,
  darkSecondary: `#28051a`,
  primary2: `#3498db`,
  secondary2: `#00bcd4`,
  background: {
    light:  colors.mainWhite,
    dark: colors.mainBlack
  },
  backgroundElevated: {
    light:  colors.mainWhite,
    dark: colors.mainBlack2
  },
  bodyTitle: {
    light:  colors.mainBlack,
    dark: `hsla(0,0%,100%,0.93)`,
  },
  bodyText: {
    light:  colors.mainBlack,
    dark: `hsla(0, 0%, 100%, 0.88)`,
  },
  link: {
    light:  ()=> themeColors.primary,
    dark:   ()=> themeColors.secondary,
  },
  body2: {
    light:  colors.lightGrey,
    dark:   'hsl(0deg 0% 98% / 71%)',
  },
  veryLight: {
    light:  colors.veryLightGrey,
    dark:   colors.veryLightGrey,
  },
  hover: {
    light:  ()=> themeColors.secondary2,
    dark:   ()=> themeColors.primary2,
  },
  visited: {
    light:  ()=> colors.veryLightGrey,
    dark:   ()=> colors.veryLightGrey
  },
  icons: {
    light: `rgba(0,0,0,0.45)`,
    dark: `rgba(196, 195, 195, 0.45)`
  }
  
}


export const components = {
  codeBlocks : {
    background: {
      light: colors.veryLightGrey,
      dark: colors.mainBlack2
    },
    border: {
      light: colors.lightGrey,
      dark: colors.veryLightGrey
    },
  },
  fileName: {
    background:colors.darkBlack,
    textColor: colors.mainWhite
  },
  mobileNavBoxShadow: {
    light: '0 50px 100px rgba(50, 50, 93, .1), 0 15px 35px rgba(50, 50, 93, .15), 0 5px 15px rgba(0, 0, 0, .1)',
    dark: 'rgb(136 153 166) 0px 0px 15px 0px, rgb(136 153 166) 0px 0px 4px 2px;'
  },
  blockquote: {
    background: {
      light:  ()=> '#fff3bf',
      dark:   ()=> '#2a4661',
    },
    font: {
      light:  ()=> colors.mainBlack,
      dark:   ()=> 'rgba(255,255,255,0.9)',
    }
  }
}

