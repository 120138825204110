import React from 'react';
import styled from 'styled-components';

const RoundedButton = ({name, link, sideSpacing = true}) => {
  return (
      <>
        <Button sideSpacing={sideSpacing} href={link}>{name}</Button>
      </>
  );
};

const Button = styled.a`
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  display: block;
  font-weight: 600;
  padding: .3rem .9rem;
  font-size: 0.8rem;
  border-radius: 3rem;
  //margin: ${props => props.sideSpacing? '0rem 0.4rem 0.2rem 0.4rem' : '.2rem 0rem .2rem 0rem'};
  margin-left: 0.4rem;
  white-space: nowrap;
`;

export default RoundedButton;
