import React from 'react';
import {siteConfig} from '../utils/site-config';
import {Link} from 'gatsby';
import logo from '../../static/logos/logo.svg';
import styled from 'styled-components';
import MobileNavMenu from './MobileNavMenu';
import * as variables from '../styles/style-config'
import ThemeTogglerButton from './ThemeToggler';
const menuLinks = siteConfig.navLinks;

const NavBar = () => {
  return (
      <NavGrid>
        <LogoContainer>
          <Link to="/">
            <LogoImg src={logo} className="logo" title="Personal website and blog for Abdullah (abba), a Full-Stack Web Developer." alt="abba.dev logo image" loading="lazy"/>
          </Link>
        </LogoContainer>
        <MobileNavMenu/>
        <MenuList>
          {menuLinks.map(link => (
              <MenuItem key={link.name}>
                <Link
                    key={link.name}
                    to={link.link}
                    activeClassName={link.link === '/' ? null : 'active'}
                    partiallyActive={true}
                >
                  {link.name}
                </Link>
              </MenuItem>
          ))}
        </MenuList>
        <ThemeTogglerButton/>
      </NavGrid>
  );
};

export const NavGrid = styled.nav`
  grid-column: 1/1;
  grid-row: 1 / span 1;
  display: grid;
  align-items: center;
  height: 7.5rem;
  grid-template-columns: 1fr 2fr auto;
  @media (min-width: ${variables.breakpoints.small}) {
        grid-column: 2/3;
  }
`;

const LogoContainer = styled.div`
    grid-column: 1/span 1;
    display: grid;
    justify-self: start;
    @media (min-width: ${variables.breakpoints.small}) {
      //display: none;
    }
`;

const LogoImg = styled.img`
    width: auto;
    height: 2.5rem;
`

const MenuList = styled.ul`
  display: none;
  grid-auto-flow: column;
  list-style: none;
  justify-self: end;
  grid-column-gap: 1.2rem;
  @media (min-width: ${variables.breakpoints.small}) {
        display: grid;
  }
`
 const MenuItem = styled.li`
  a {
    color: var(--textTitle);
    font-size: 0.9rem;
    font-weight: 400;
    text-decoration: none;
    transition: color 0.3s ease-out;
    white-space: nowrap;
    &.active {
      color: var(--menu-active-color);
    }
  }
  
  a:hover {
    color: var(--textLinkHover);
  }
  
`

export default NavBar;
