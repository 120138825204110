import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import * as variables from '../styles/style-config';
import {siteConfig} from '../utils/site-config';

const BlogItemMinimal = (data) => {
  const {title, category, date, slug} = data;
  const categoryData = siteConfig.categories.filter(c => c.slug === category)[0];
  
  return (
      <Link key={slug} to={`/blog/${slug}`}>
        <BlogItemContainer className="blogItemList">
          <Title>{title}</Title>
          <TitleContainer>
            <MetadataContainer>
              <div>{date}</div>
              <RedDot/>
              <div>{categoryData.name} </div>
            </MetadataContainer>
          </TitleContainer>
        </BlogItemContainer>
      </Link>
  );
};



const Title = styled.h4`
	align-self: center;
	font-weight: 500;
`;


export const BlogItemContainer = styled.div.attrs(props => ({
  className: props.className,
}))`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-column-gap: 1.5rem;
	padding: 0.8rem 0;
	@media (min-width: ${variables.breakpoints.small}) {
      border-bottom: 1px solid var(--light-grey);
  }
	&:hover {
		${Title} {
		color: var(--textLinkHover)
		}
	}
`;

const TitleContainer = styled.div`
		grid-column: span 1/ -1;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
		margin-top: 0.5rem;
`;

const MetadataContainer = styled.div`
	grid-row: span 1/ -1 ;
	display: flex;
	flex-wrap: wrap;
	color: var(--light-grey);
	font-size: 0.8rem;
	font-weight: 400;
	align-items: center;
`;

const RedDot = styled.span`
	width: 0.25rem;
	height:  0.25rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	background: var(--secondary-theme-color);
	border-radius: 9999px;
`;

export {BlogItemMinimal};
