import styled from 'styled-components';
import * as variables from '../styles/style-config';

export const WrapperGrid = styled.div`
  display: grid;
  grid-gap: 1.2rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr 6rem;
  min-height: 95vh;
  margin: 0 1rem;
 @media (min-width: ${variables.breakpoints.small}) {
    width: calc(98vw - 34px);
  	grid-template-columns: ${props => props.isPost ? '1fr 5fr 1fr' : '3fr 5fr' };
	}
	@media (min-width: ${variables.breakpoints.big}) {
      grid-template-columns: [sidebar-start] 3fr [sidebar-end] 5fr [center-end] 2fr [margin-end];
  }
`;

export const MainGrid = styled.main`
  display: grid;
  grid-row-start: 2 / span 1;
  grid-column: 1/-1;
  //min-height: calc(100vh - 310px);
`;

export const TitleEdge = styled.div`
  justify-self: start;
  align-self: center;
  display: none;
  letter-spacing: 0.025em;
  font-size: 1.5rem;
  font-weight: 600;
  //text-transform: uppercase;
  & a {
   background: -webkit-linear-gradient(left, var(--primary-theme-color), var(--secondary-theme-color));
   background: -o-linear-gradient(right, var(--primary-theme-color), var(--secondary-theme-color));
   background: -moz-linear-gradient(right, var(--primary-theme-color), var(--secondary-theme-color));
   background: linear-gradient(to right, var(--primary-theme-color), var(--secondary-theme-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
  @media (min-width: ${variables.breakpoints.small}) {
      display: block;
      justify-self: start;
      margin-left: 3rem;
  }
`;

export const FooterStyled = styled.footer`
  display: grid;
  grid-column: 1/-1;
  grid-row: span 1 / -1;
`;
