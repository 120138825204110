import React from 'react';
import styled from 'styled-components';
import * as variables from '../styles/style-config'

const PostLayout = ({ title, description, subtitle, children, postFooter}) => {
  return (
      <Wrapper>
        <TopContainer>
          <Title>
            {title}
            <p>{description}</p>
          </Title>
          
          <SubTitle>{subtitle}</SubTitle>
        </TopContainer>
        <ChildrenContainer>
          {children}
        </ChildrenContainer>
        <PostFooter>{postFooter}</PostFooter>
      </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1.2rem;
  grid-template-columns: 1fr;
  grid-template-rows: [ title-start] max-content [title-end children-start] auto [children-end footer-mobile-start] max-content [footer-mobile-end];
   @media (min-width: ${variables.breakpoints.small}) {
      grid-template-columns: [sidebar-start] 1fr [sidebar-end] 5fr [center-end] 1fr [margin-end];
  }
  @media (min-width: ${variables.breakpoints.big}) {
   grid-template-columns: [sidebar-start] 3fr [sidebar-end] 5fr [center-end] 2fr [margin-end];
  }
`;

const TopContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-row: 1/ span 1;
  grid-column: 1 / 1;
   @media (min-width: ${variables.breakpoints.small}) {
       grid-column: 2/ span 1;
  }
`

const Title = styled.div`
  grid-row: 1/ span 1;
  grid-column: 1 / span 1;
`

const SubTitle = styled.div`
  margin-top: 0.1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--very-light-grey);
  grid-row: span 1/ -1;
  grid-column: 1 / span 1;
`

const PostFooter = styled.div`
display: grid;
	grid-row: footer-mobile-start /footer-mobile-end ;
	grid-column: span 1 / -1;
   @media (min-width: ${variables.breakpoints.small}) {
   margin-top: 2rem;
   	grid-column: 2/ span 1;
   	justify-items: center;
   }
	
`;

const ChildrenContainer = styled.div`

	grid-row: children-start/ children-end;
  grid-column: 1 / -1;
   @media (min-width: ${variables.breakpoints.small}) {
       grid-column: 2/ span 1;
       grid-row: title-end / children-end;
  }
  
  min-height: 0;
  min-width: 0;
  h1, h2,h3,h4, h5 {
    border-bottom: 1px solid var(--very-light-grey);
  }
  
  h1, h2,h3,h4, h5, h6 {
    margin-top: 2rem;
    position: relative;
    a {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      padding-right: 4px;
      text-decoration: none;
      display: none;
    }
    &:hover {
      a{
         @media (min-width: ${variables.breakpoints.small}) {
            display: block;
        }
      }
    }
  }
  p {
  font-family: var(--body-font);
  overflow: hidden;
   min-width: 0;
  }
`

export  {PostLayout};
