import React from 'react';
import {GlobalLayout, PageBodyLayout} from '../layouts';
import {Helmet} from 'react-helmet';
import NotFoundImage from '../../assets/NotFoundImg.svg';

const Title = () => (
    <>
      <h2>Page Not Found</h2>
    </>
);

const NotFoundPage = () => {
  return (
      <>
        <GlobalLayout>
          <Helmet title='Not Found'/>
          <PageBodyLayout title={<Title/>}
                          subtitle={<p> Oops. This page doesn't exist.</p>} image={<NotFoundImage/>}>
          </PageBodyLayout>
        </GlobalLayout>
      </>
  );
};

export default NotFoundPage;
