import React from 'react';
import styled from 'styled-components';
import * as variables from '../styles/style-config';

const NewsletterForm = () => {
  return (
      <FormContainer>
        <Form name="subscribe"
              method="POST"
              action="https://buttondown.email/api/emails/embed-subscribe/abbadev">

          <Input type="email" name="email" id="bd-email" placeholder="Enter your email" required aria-labelledby="email-label"/>
          {/*<input type="hidden" value="1" name="embed"></input>*/}
          <Button type="submit">Subscribe</Button>
          <Label id="email-label" htmlfor="email">Email</Label>
        </Form>
      </FormContainer>
  );
};

 const FormContainer =styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  grid-template-columns: minmax(30%, 450px);
 @media (min-width: ${variables.breakpoints.small}) {
   justify-content: center;
 }
`
const Form = styled.form`
  display: grid;
  grid-template-columns: 2fr 1fr;
  //grid-gap: 0.2rem;
`

 const Input = styled.input`
  width: 100%;
  border: 1px solid var(--light-grey);
  background-color: var(--bg2);
  padding: 0.5rem;
  color: var(--bodyText);
  border-radius: 4px 0 0 4px ;
`;

const Label = styled.label`
  color: var(--bodyText);
  font-size: 0.8rem;
  visibility: hidden;
`

 const Button = styled.button`
  padding: 0.5rem;
  margin-left: -0.1rem ;
  width: 100%;
  font-weight: 600;
  background-color: var(--menu-active-color);
  border-radius: 0 4px 4px 0;
  border: 1px solid var(--menu-active-color) ;
  cursor: pointer;
  color: black;
  text-transform: uppercase;
  
  &:hover {
   border-color:  var(--textLinkHover);
  background-color: var(--textLinkHover);
  }
`;

export default NewsletterForm;
