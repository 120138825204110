import styled from "styled-components";

const Code = styled.code`
  background: var(--codeblockbg);
  border: 1px solid var(--codeblockborder);
  white-space: nowrap;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 0.9375em;
`;

export default Code;
