import React from 'react';
import {BlogItemMinimal} from './BlogItemMinimal';
import styled from 'styled-components';

const BlogListMinimal = ({edges}) => {
  return (
      <>
        <BlogListStyled>
          {edges.map((post, index) => {
            const {title, date, slug, category} = post.node.frontmatter;
            const {timeToRead} = post.node;
            return (
                <BlogItemMinimal key={index}
                                 slug={slug}
                                 title={title} category={category}
                                 date={date} readTime={timeToRead}/>
            );
          })}
        </BlogListStyled>
      </>
  );
};

const BlogListStyled = styled.div`
    margin-top: 1rem;
`;

export default BlogListMinimal;
