import React from 'react';
import dayjs from 'dayjs';

const AgeCounter = ({date, unit}) => {
  const difference = dayjs().diff(dayjs(date), unit);
  return (
      <span>
        {difference}
      </span>
  );
};

export default AgeCounter;
