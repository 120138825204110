import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import {siteConfig} from '../utils/site-config';

const TagStyle = ({category, sideSpacing = true}) => {
  
  const categoryLc = category.toLowerCase();
  const categoryData = siteConfig.categories.filter(c=> c.slug===category)[0]
  const selectedTheme = theme[categoryLc] ? theme[categoryLc] : theme.default;
  return (
      <>
  
        <ThemeProvider theme={selectedTheme}>
          <Tag sideSpacing={sideSpacing} href={`/categories/${categoryData.slug}`}>{categoryData.name}</Tag>
        </ThemeProvider>
      </>
  );
};

const theme = {
  code: {
    background: "#fff3bf",
    color: "#000",
  },
  'software-design': {
    background: "#fff4e6",
    color: "#e8590c",
  },
  tutorials: {
    background: "#fff0f6",
    color: "#c2255c",
  },
  personal: {
    background: "#d3f9d8",
    color: "#111",
  },
  cloud: {
    background: "#e3fafc",
    color: "#0c8599",
  },
  aws: {
    background: "#FF9900",
    color: "#000000",
  },
  default: {
    background: "#f1f3f5",
    color: "#3d78b3",
  }
};

const Tag = styled.a`
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  display: block;
  font-weight: 500;
  padding: .4rem .6rem;
  font-size: 0.75rem;
  border-radius: 3rem;
  line-height: 1;
  margin: ${props => props.sideSpacing? '.2rem .2rem .2rem 0.4rem' : '.2rem 0rem .2rem 0rem'};
`;

export default TagStyle;
