import styled from "styled-components";

import React from 'react';

const FootNote = ({children}) => {
  return (
      <FootnoteStyle>
        {children}
      </FootnoteStyle>
  );
};

export default FootNote;

const FootnoteStyle = styled.p`
  padding-top: 1rem;
  font-size: 0.7rem;
  font-weight: 300;
  border-top: 0.8px solid;
`;

