import React from 'react';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import {graphql} from 'gatsby';
import {GlobalLayout, PostLayout} from '../layouts';
import {MDXProvider} from '@mdx-js/react';
import mdxComponents from '../components/mdxComponents';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';
import TagStyle from '../components/TagStyle';
import PostFooter from '../components/PostFooter';
import SEO from '../components/SEO';

const PostTemplate = ({data, pageContext}) => {
  
  const {title, date, description, category, tags, slug, image} = data.mdx.frontmatter;
  const {prev, next, otherCategoryPosts} = pageContext;
  const {body, timeToRead} = data.mdx;
  
  const Title = () => (
      <>
        <h1>{title}</h1>
      </>
  );
  
  const Subtitle = () => (
      <MetadataContainer>
        <TagStyle sideSpacing={false} category={category}/>
        <BullDot/>
        <div>{date}</div>
        <BullDot/>
        <div> {timeToRead} min read</div>
      </MetadataContainer>
  )
  
  return (
      <>
        <GlobalLayout isPost={true}>
          <Helmet title={title}/>
          <SEO path={`/blog/${slug}`} customTitle={title} customDescription={description} customImage={image.childImageSharp.fluid.src} postSEO/>
          <section>
            <PostLayout title={<Title/>}
                            subtitle={<Subtitle/>} description={description} postFooter={<PostFooter category={category} tags={tags} previous={prev} next={next} otherCategoryPosts={otherCategoryPosts}/>}>
            <div>
              <MDXProvider components={mdxComponents}>
                <MDXRenderer>
                  {body}
                </MDXRenderer>
              </MDXProvider>
            </div>
            </PostLayout>
          </section>
        </GlobalLayout>
      </>
  );
};

export const query = graphql`
  query getPost($slug: String!) {
      mdx(frontmatter: { slug: { eq: $slug } }) {
        frontmatter {
          title
          description
          slug
          date(formatString: "MMMM Do, YYYY")
          category
          tags
          image {
            childImageSharp {
              fluid( quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body
        timeToRead
      }
    }`;

const MetadataContainer =styled.div`
	grid-row: 1/ span 1 ;
	display: flex;
	flex-wrap: wrap;
	color: var(--light-grey);
	font-size: 0.8rem;
	font-weight: 400;
	align-items: center;
`;


const BullDot = styled.span`
	width: 0.25rem;
	height:  0.25rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	background: var(--light-grey);
	border-radius: 9999px;
`;

export default PostTemplate;
