import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'gatsby';
import {siteConfig} from '../utils/site-config';
import styled from 'styled-components';
import * as variables from '../styles/style-config'
const menuLinks = siteConfig.navLinks;

const MobileNavMenu = () => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  useEffect(() => {

    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setOpen(false)
       
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  
  const togglePopup = () => {
    setOpen(!isOpen);
  }
  
  const togglePopupKey = (event) => {
    if (event.key === "Enter") {
      togglePopup();
    }
  }
  
  return (
      <Container>
        <BurgerIcon aria-label="Open popup mobile menu" onClick={togglePopup} onKeyPress={togglePopupKey} role={"button"} tabIndex={"0"} aria-pressed={'false'}>
          <BurgerIconInner />
          <BurgerIconInner />
          <BurgerIconInner />
        </BurgerIcon>
        <MobileUl >
            <Popup ref={wrapperRef} isOpen={isOpen}>
              <PopupContainer isOpen={isOpen}>
                <MenuHeader>
                  <CloseButtonContainer>
                    <CloseButton aria-label="Close Button" onClick={togglePopup} role={"button"} onKeyPress={togglePopupKey} tabIndex={"0"} aria-pressed={'false'}>Close</CloseButton>
                  </CloseButtonContainer>
                  
                 <HeaderTitle> <Link to="/">abba.dev</Link></HeaderTitle>
                  <MenuLinksContainer>
                    <MobileMenuList>
                      {menuLinks.map(link => (
                          <MenuItem key={link.name}>
                            <Link key={link.name}
                                  to={link.link}
                                  className="linkContainer"
                                  activeClassName={link.link === '/' ? null : 'active'}
                                  partiallyActive={true}
                            >
                                {link.name}
                            </Link>
                              </MenuItem>
                            
                      ))}
                    </MobileMenuList>
                  </MenuLinksContainer>
                </MenuHeader>
              </PopupContainer>
            </Popup>
        </MobileUl>
      </Container>
  );
};

const Container = styled.div`
    overflow: inherit;
    display: grid;
    grid-template-columns: [nav-start] 1fr [margin-gap];
    grid-template-rows: 1fr;
    align-items: center;
     @media (min-width: ${variables.breakpoints.small}) {
          display: none;
  }
`

const BurgerIcon = styled.div`
  justify-self: end;
  cursor: pointer;
  grid-column: span 1 / margin-gap;
  grid-row: span 1 / -1;
  display: grid;
  grid-template-columns: 1.5rem;
  grid-template-rows: 0.1875rem 0.1875rem 0.1875rem;
  grid-row-gap: 0.475rem;
  width: 2.25rem;
  padding: 1.875rem;
  align-content: center;
  justify-content: center;
  outline: none;
`

const BurgerIconInner = styled.div`
   background-image: -webkit-linear-gradient(left, var(--primary-theme-color), var(--secondary-theme-color));
   background-image: -o-linear-gradient(right, var(--primary-theme-color), var(--secondary-theme-color));
   background-image: -moz-linear-gradient(right, var(--primary-theme-color), var(--secondary-theme-color));
   background-image: linear-gradient(to right, var(--primary-theme-color), var(--secondary-theme-color));

`

const MobileUl = styled.div`
  perspective: 125rem;
  display: grid;
  grid-template-rows: auto;
  grid-column: span 1 / margin-gap;
  grid-row: span 1 / -1;
  align-self: start;
`

const Popup = styled.div`
    position: absolute;
    left: 0.625rem;
    top: 1rem;
    right: 0.5rem;
    display: grid;
    pointer-events: ${props=> props.isOpen ? "auto" : "none"};
    perspective: 125rem;
`;

const PopupContainer = styled.div`
    background: var(--bg2);;
    box-shadow: var(--mobile-menu-shadow);
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    //font-size: 17px;
    line-height: 40px;
    white-space: nowrap;
    transform: ${props => props.isOpen ? "none" : "rotate3d(1, 1, 0, -15deg)"}; ;
    transform-origin: 100% 0;
    opacity: ${props => props.isOpen ? 1 : 0};
    will-change: transform, opacity;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    transition-duration: .20s
`

const MenuHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 1fr max-content;
`

const CloseButtonContainer = styled.div`
  position: relative;
  grid-column: span 1/ -1;
  height: 51px;
`

const CloseButton = styled.a`
    position: absolute;
    grid-column: span 1/ -1;
    right: 0;
    top: 0;
    width: 51px;
    font-size: 0;
    cursor: pointer;
    &::before, &::after {
      content: '';
      position: absolute;
      background: var(--primary-theme-color);
      border-radius: 1px;
      left: 14px;
      right: 14px;
      top: 24px;
      height: 3px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: background .1s;
      transition: background .1s;

    }
    &::after {
        -webkit-transform: rotate(-45deg);
       transform: rotate(-45deg)
    }
    &:hover {
     &::before, &::after {
       background: var(--textLinkHover)
       }
     }
`

const HeaderTitle = styled.div`
  grid-column: 1/ span 1;
  grid-row: 1/ span 1;
  font-size: 0.9rem;
  font-weight: 900;
  
  letter-spacing: 0.025em;
  padding: 6px 20px;
  & a {
   background: -webkit-linear-gradient(left, var(--primary-theme-color), var(--secondary-theme-color));
   background: -o-linear-gradient(right, var(--primary-theme-color), var(--secondary-theme-color));
   background: -moz-linear-gradient(right, var(--primary-theme-color), var(--secondary-theme-color));
   background: linear-gradient(to right, var(--primary-theme-color), var(--secondary-theme-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }
`

const MenuLinksContainer = styled.div`
  grid-row:  span 1/ -1;
  grid-column: 1/ -1;
`

const MobileMenuList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  list-style: none;
  justify-items: stretch;
  justify-content: center;
  padding: 0;
  & a {
    text-decoration: none;
  }
`

 const MenuItem = styled.li`
  display:block;
  text-align: center;
  font-weight: 600;
  line-height: 50px;
  a {
    transition: color 0.3s ease-out;
    display: block;
    color: var(--textTitle);
   &.active {
      color: var(--menu-active-color);
    }
  }
  
  a:hover {
    color: var(--textLinkHover);
  }
  
`


export default MobileNavMenu;
