import React from 'react';
import {Helmet} from 'react-helmet';
import { siteConfig } from '../utils/site-config';

const SEO = ({
             path,
             postSEO,
             customTitle,
             customImage,
             customDescription,
             }) => {
  
  let title = customTitle || siteConfig.siteTitle;
  let description = customDescription ? customDescription : siteConfig.description;
  let imageSlug = customImage ? customImage : siteConfig.siteLogo;
  let image = `${siteConfig.siteUrl}${imageSlug}`;
  let fullPath = path ? `${siteConfig.siteUrl}${path}` : siteConfig.siteUrl;
  
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: siteConfig.siteUrl,
      name: title,
      alternateName: title,
    },
  ];
  
  if ( postSEO ) {
    schemaOrgJSONLD.push(
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': path,
                name: title,
                image,
              },
            },
          ],
        },
        {
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          url: siteConfig.siteUrl,
          name: title,
          alternateName: title,
          headline: title,
          image: {
            '@type': 'ImageObject',
            url: image,
          },
          description,
        },
    );
  }
  
  return (
      <Helmet>
        <meta name="description" content={description}/>
        <meta name="image" content={image}/>
        
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
        <meta property="og:url" content={fullPath}/>
        {postSEO ?
            <meta property="og:type" content="article"/> :
            <meta property="og:type" content="website"/>}
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description}/>
        <meta property="og:image" content={image}/>
        <meta property="og:locale" content="en_us" />
  
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:creator" content={siteConfig.twitterUser}/>
        <meta name="twitter:title" content={title}/>
        <meta name="twitter:description" content={description}/>
        <meta name="twitter:image" content={image}/>
      </Helmet>
  );
};

export default SEO;
