import React from 'react';
import Code from './code';
import BlockQuote from './blockquote';
import Filename from './filename';
import FootNote from './FootNote';

export default {
  inlineCode: props => <Code {...props} />,
  Filename: props => <Filename {...props} />,
  blockquote: props => <BlockQuote {...props} />,
  FootNote: props => <FootNote {...props } />
}
