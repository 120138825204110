import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import {Helmet} from 'react-helmet';
import {siteConfig} from '../utils/site-config';
import favicon from '../../static/icons/favicon.ico';
import appleIcon from '../../static/logos/apple-touch-icon.png';
import {createGlobalStyle} from 'styled-components';
import {MainGrid, TitleEdge, WrapperGrid} from './GridLayouts';
import {Link} from 'gatsby';
import "../fonts/font.css"
import * as variables from '../styles/style-config'
import {ThemeProvider, useThemeContext} from '../context/theme-context';

const GlobalLayout = ({children, isPost=false}) => {
  return (
      <>
        <ThemeProvider>
          <GlobalLayoutInt isPost={isPost}>
            {children}
          </GlobalLayoutInt>
        </ThemeProvider>
        </>
  )
}

const GlobalLayoutInt = ({children, isPost=false}) => {
  const { theme } = useThemeContext();
  return (
      <>
          <GlobalStyle/>
          <Helmet title="abba.dev" titleTemplate="%s - abba.dev">
            <meta name="description" content={siteConfig.description}/>
            <meta name="theme-color" content={theme && theme === 'dark' ?  variables.themeColors.backgroundElevated.dark :  variables.themeColors.primary} />
            <link rel="shortcut icon" type="image/png" href={favicon}/>
            <link rel="apple-touch-icon" type="image/png" href={appleIcon}/>
            <html lang="en"/>
            <script async defer data-domain="abba.dev" src="https://plausible.io/js/plausible.js"></script>
          </Helmet>
          <WrapperGrid isPost={isPost}>
            {!isPost && <TitleEdge><Link to="/">abba.dev</Link></TitleEdge> }
            <NavBar/>
            <MainGrid>
              {children}
            </MainGrid>
            <Footer/>
          </WrapperGrid>
      </>
  );
};

const GlobalStyle = createGlobalStyle`
:root {
--base-font: 'Open Sans', -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", Arial,sans-serif;
 --heading-font: 'Raleway', -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto, Oxygen, Ubuntu, Cantarell, "Helvetica Neue", Arial,sans-serif;
 --body-font: 'Open Sans', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
 --primary-theme-color: ${variables.themeColors.primary};
 --secondary-theme-color: ${variables.themeColors.secondary};
 --children-max-width: 850px
}

:root {
  --grvsc-line-highlighted-background-color: rgba(245,203,156,0.36);
  --grvsc-line-highlighted-border-color:  ${variables.themeColors.primary};
  --grvsc-line-highlighted-border-width: 2px;
}


html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
  font-family: var(--base-font);
  @media (min-width: ${variables.breakpoints.verySmall}) {
    font-size: calc(1rem + 3 * ((100vw - 20rem) / 880));
  }
  @media (min-width: ${variables.breakpoints.large}) {
    font-size: 1.2rem;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

// Remove figure margin
figure {
  margin: 0;
}
body {
  margin: 0;
  --bg: ${variables.themeColors.background.light};
  --bg2: ${variables.themeColors.backgroundElevated.light};
  --bodyText: ${variables.themeColors.bodyText.light};
  --textTitle: ${variables.themeColors.bodyTitle.light};
  --textLink: ${variables.themeColors.link.light};
 --textLinkHover: ${variables.themeColors.hover.light};
  --textLinkVisited: ${variables.themeColors.visited.light};
 --hr: hsla(0, 0%, 0%, 0.2);
 --light-grey: ${variables.themeColors.body2.light};
 --very-light-grey: ${variables.themeColors.veryLight.light};
 --icons: ${variables.themeColors.icons.light};
  background-color: var(--bg);
   --mobile-menu-shadow: ${variables.components.mobileNavBoxShadow.light};
   --codeblockbg: ${variables.components.codeBlocks.background.light};
   --codeblockborder: ${variables.components.codeBlocks.border.light};
   --cardShadow:  0 3px 13px rgba(100, 110, 140, 0.1),
    0 2px 4px rgba(100, 110, 140, 0.15);
   --cardShadowHover : 0 8px 22px rgba(100, 110, 140, 0.2),
        0 5px 18px rgba(100, 110, 140, 0.25);
   --menu-active-color: var(--primary-theme-color);
   --blockquote-bg: ${variables.components.blockquote.background.light};
   @media(max-device-width: 480px) {
      -webkit-text-size-adjust: none;
    }
}

body.dark {
    -webkit-font-smoothing: antialiased;
  
    --bg: ${variables.themeColors.background.dark};
    --bg2: ${variables.themeColors.backgroundElevated.dark};
    --bodyText: ${variables.themeColors.bodyText.dark};
    --textTitle: ${variables.themeColors.bodyTitle.dark};
    --textLink: ${variables.themeColors.link.dark};
    --textLinkHover: ${variables.themeColors.hover.dark};
    --textLinkVisited: ${variables.themeColors.visited.dark};
    --light-grey: ${variables.themeColors.body2.dark};
    --very-light-grey: ${variables.themeColors.veryLight.dark};
     --icons: ${variables.themeColors.icons.dark};
    --hr: hsla(0, 0%, 100%, 0.2);
    
    --mobile-menu-shadow: ${variables.components.mobileNavBoxShadow.dark};
    --codeblockbg: ${variables.components.codeBlocks.background.dark};
    --codeblockborder: ${variables.components.codeBlocks.border.dark};
    --cardShadow:  0 3px 13px rgba(100, 110, 140, 0.1), 0 2px 4px rgba(100, 110, 140, 0.15);
    --cardShadowHover : 0 3px 13px rgb(100 110 140 / 40%), 0 2px 4px rgba(100,110,140,0.15);
    --menu-active-color: var(--secondary-theme-color);
    --blockquote-bg: ${variables.components.blockquote.background.dark};
  }
  
 .theme-changer {
  display: none;
  }
  body.dark {
    .gg-moon {
      display: none;
    }
  }
  body.light {
    .gg-sun {
      display: none;
    }
  }

a {
  text-decoration: none;
  color: var(--textLink);
}

h1, h2, h3, h4, h5, h6 {
 margin: 0;
 color: var(--textTitle);
 font-family: var(--heading-font);
 font-weight: 600;
}

h1 {
 font-size: 1.7em;
}

h2 {
 font-size: 1.5em;
}

h3 {
 font-size: 1.2em;
}

h4 {
 font-size: 1.1em;
}

h5 {
 font-size: 1.1em;
}

h6 {
 font-size: 1em;
}

p {
  font-size: 1em;
  line-height: 1.8rem;
  color: var(--bodyText)
}

li {
  line-height: 1.8rem;
  color: var(--bodyText)
}

pre[data-language] {
margin: auto ;
}

//wrap code blocks or let them scroll
//pre {
//  white-space: pre-wrap;
//  word-break: keep-all;
//}

`;

export {GlobalLayout};
