module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers"},{"resolve":"gatsby-remark-images","options":{"maxWidth":500,"withWebp":true,"quality":99,"disableBgImageOnAlpha":true}},{"resolve":"gatsby-remark-vscode","options":{"theme":"Cobalt2","extensions":["theme-cobalt2"]}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/abdullah/dev/abba.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://abba.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-177913998-1","anonymize":true,"respectDNT":true,"head":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://c5864a5f75f3495aa538141441df3179@o449186.ingest.sentry.io/5431675","sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Abdullah (abba) - Software Developer","short_name":"abba.dev","description":"Personal website and blog for Abdullah (abba), a Full-Stack Web Developer. I like to talk about web development and how apps gets deployed to the cloud.","start_url":"/","lang":"en","icon":"static/logos/logoImg.png","background_color":"#fff","theme_color":"#f8821e","display":"standalone","theme_color_in_head":false,"icon_options":{"purpose":"maskable"},"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8e742f996175e07d0d313954e7694cdc"},
    }]
